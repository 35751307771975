<script>
/**
 * vue component 분리 가이드
 *
 * - 공통으로 사용하는 data 및 함수를 넣는다. 객체 형태라면 수정에도 큰 문제가 없다.
 *   parent: v-bind="{shop, category, categoryMap, down, handleXlsx}"
 *   child: props: ['shop', 'category', 'categoryMap', 'down', 'handleXlsx'],
 *   만약 다른 component 에 사용되지 않고 독립적으로 사용된다면 child 로 이관한다. 이 때, busy 같이 공통으로 사용되는 데이터도 분리할 수 있다.
 *
 * - child 에서만 사용하는 함수를 가져온다.
 *   이 함수를 부모가 사용하기 위해 parent의 child tag 에 ref="tabRegistered" 를 설정한다. this.$refs.tabRegistered.someFunc() 형태로 호출한다.
 *
 * - created, mounted, beforeDestroy 등에서 공통으로 들어간 내역을 분리해서 옮겨온다.
 *
 * - ui 변경을 위해 $forceUpdate 가 필요할 수 있다.
 */
import * as utils from '@/shared/utils'
import {afterChangeGen, cfColumns} from '@/shared/ht_helper';
import shopPreset from '@/views/ShopPreset.vue'
import brandPreset from '@/views/BrandPreset.vue'
import categoryPreset from '@/views/CategoryPreset.vue'
import vSelect from "vue-select";
import {HotTable} from "@handsontable/vue";
const regColBase = utils.clone(cfColumns).filter(e => e.registered);

export default {
  name: "ProcessingTabRegistered",
  props: ['shop', 'category', 'categoryMap', 'down', 'handleXlsx'],
  components: {vSelect, shopPreset, brandPreset, categoryPreset, HotTable},
  data() {
    return {
      utils, regColBase,
      formRegistered: {
        search: '',
        shop: [],
        brand: [],
        category: [],
        orderby: 'goods_no',
        goods_status: 'registered',
        stock_status: 'ALL',
        display_status: 'ALL',
        manual: 'ALL',
        goodsType: 'ALL',
        usedGrade: this.$C.USED_GRADE.map(e => e.value),
        goods_no_from: '',
        goods_no_to: '',
        goods_no: '',
        limit: 500,
        skip: 0,
      },
      lastForm: {

      },
      busy: {registered: false, registeredMore: false},
      modal: {saveRegistered: false, registeredField: false},
      items: {
        registered: [],
      },
      item: {},
      hasMore: {registered: false},
      ac: {}, // abortController
      changed: {registered: 0},
      fields: {
        registered_ht: [
          {data: 'selected', name: '', width: 80, type: 'checkbox'},
          {data: 'img_urls', name: '이미지', width: 80, readOnly: true, renderer: 'image'},
          {
            data: 'goods_no', name: '상품정보', width: 380, readOnly: true,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
              let e = this.items.registered[cellProperties.row];
              let opt = e.options.filter(e=>!e.not_found);
              td.innerHTML = `<div class="mb-n1"><a href="/#/shop/${e.shop_id}" target="_blank" class="badge badge-success">${e.shop_id}. ${e.shop}</a> <span class="badge badge-warning">${e.brand_nm}</span>`
                + ` <span class="badge badge-light" title="${e.category}">${e.cate_name}</span>`
                + `</div>`
                + `<div class="mb-n1"><a href="/#/goods/${e.goods_no}" class="badge badge-primary" target="_blank">${e.goods_no}</a> `
                + (e.consumer !== e.price ? `<small><del>${utils.comma(e.consumer)}</del> 원</small> →` : '')
                + ` <small>${utils.comma(e.price)} 원</small>`
                + (e.manual ? ` <span class="badge alert-danger">파트너관리</span>` : '')
                + `</div>`
                + `<small><b>${e.options && e.options.length ? e.options[0].optnm : ''}</b></small> `
                + `${opt.slice(0,3).map(e=>
                  `<span class="badge badge-light">${e.Size}</span><span class="badge badge-${e.stock > 0 ? 'success' : 'secondary'}" title="판매 가능수량">${e.stock}</span>`
                ).join(' ')}${opt.length > 3 ? `<small> 외 ${opt.length - 3} 개 옵션</small>` : ''}`;
            }
          },
          {data: 'goods_nm', name: '상품명', width: 300, renderer: 'modified'},
        ]
      },
      hotSettingRegistered: {
        data: [],
        colHeaders: (index) => {
          if (index === 0) {
            return `${this.items.registered.filter(e=>e.selected).length}/${this.items.registered.length}`;
          } else if (index < 4) {
            return this.fields.registered_ht[index].name;
          }
          return this.registeredFieldMap[this.regCols[index - 4]].name;
        },
        columns: [
          // fields.registered_ht
        ],
        className: "htCenter htMiddle",
        autoWrapCol: false,
        autoWrapRow: false,
        manualColumnResize: true,
        fixedColumnsLeft: 3,
        colWidths: [
          // fields.registered_ht
        ],
        columnSorting: true,
        height: 650,
        rowHeights: 60,
        afterChange: afterChangeGen('registered', 'hotTableRegistered').bind(this),
        licenseKey: 'non-commercial-and-evaluation',
      },
      registeredFieldMap: utils.arr2map(regColBase, 'data'),
      regColsExcept: [],
      regCols: [],
    }
  },
  created() {
    // migration
    const status = JSON.parse(localStorage.getItem('Processing.Status'));
    if (status && !status.regColsExcept) {
      if (status.registeredField) {
        const fieldMap = this.$utils.arr2map(status.registeredField, 'data', true);
        this.regColsExcept = regColBase.filter(e => !fieldMap[e.data]).map(e => e.data);
        delete status.regColsExcept;
        localStorage.setItem('Processing.Status', JSON.stringify(status));
      }
    }

    // hotTable 설정을 가져온다
    utils.getStatus('Processing', this, 'regColsExcept');
    this.regCols = regColBase.filter(e => !this.regColsExcept.includes(e.data)).map(e => e.data);

    // 설정에서 필드를 복구한다.
    this.setField();

    // formRegistered
    this.listRegistered();
  },
  async beforeDestroy() {
    Object.values(this.ac).filter(e=>e).forEach(e=>e.abort());
    utils.setStatus('Processing', this, 'regColsExcept');
  },
  methods: {
    async listRegistered(more) {
      let form;
      if (more === true) {
        if (this.busy.registeredMore) return; // 이전요청을 기다린다
        form = this.lastForm.registered;
        form.skip += form.limit;
        this.busy.registeredMore = true;
      } else {
        form = this.formRegistered;
        this.lastForm.registered = utils.clone(form);
        this.items.registered.splice(0, this.items.registered.length);
        this.busy.registered = true;
      }

      let shop = form.shop.length === this.shop.length ? [] : form.shop.map(e=>e.shop_id); // 전체 선택일 경우 비우기
      let brand = form.brand.map(e=>e.value);
      let category = form.category.map(e=>e.value);
      let goods_no = this.formRegistered.goods_no.trim() ? this.formRegistered.goods_no.trim().split(/\D+/g).map(e=>+e) : [];

      if (this.ac.registered) this.ac.registered.abort();
      this.ac.registered = new AbortController();
      let j;
      try {
        j = await this.$api.postJson('/goods/confirmed', {...form, shop, brand, category, goods_no_include: goods_no}, {signal:this.ac.registered.signal});
        j.list.forEach(e=>{
          e._org = utils.clone(e);
          e._changed = {};
          e.img = e.img_urls ? (e.img_urls[0] || '') : '';
          e.cate_name = e.category ? Array(e.category.length / 3).fill(0).map((_,i)=>this.categoryMap[e.category.substring(0, 3 + i*3)]).filter(e=>e).map(e=>e.category_nm).join(' > ') : '';
          e.selected = false;
        });
        this.items.registered = j.list;
      } catch (e) {
        console.error(e);
        if (more !== true) {
          this.items.registered = [];
          this.busy.registered = false;
        } else {
          this.busy.registeredMore = false;
        }
        return;
      } finally {
        this.ac.registered = null;
      }

      this.hasMore.registered = j.hasMore;
      if (more === true) {
        this.items.registered = this.items.registered.concat(j.list);
        this.busy.registeredMore = false;
      } else {
        this.items.registered = j.list;
        this.busy.registered = false;
        this.changed.registered = 0;
        this.item = {};
      }
      this.$refs.hotTableRegistered.hotInstance.loadData(this.items.registered);
    },
    setField() {
      // colBase 객체들로 순서에 맞게 다시 구성한다
      const fields = [...this.fields.registered_ht, ...this.regCols.map(e => this.registeredFieldMap[e])];
      this.regColsExcept = regColBase.filter(e => !this.regCols.includes(e.data)).map(e => e.data);

      let settings = {
        // colHeaders: `선택,이미지,상품정보,상품명,오늘도착여부,${this.registeredField.map(e=>e.name).join(',')}`.split(','),
        columns: fields,
        colWidths: fields.map(e => e.width),
      };
      if (this.$refs.hotTableRegistered) {
        this.$refs.hotTableRegistered.hotInstance.updateSettings(settings);
      } else {
        this.hotSettingRegistered = {...this.hotSettingRegistered, ...settings};
      }
      utils.setStatus('Processing', this, 'regColsExcept');
    },
    selectAllGoods() {
      let selectAll = !this.items.registered.every(e=>e.selected);
      this.items.registered.forEach(e=>e.selected = selectAll);
      this.$refs.hotTableRegistered.hotInstance.render();
    },
    checkRegistered(target) {
      if (target === 'all') {
        this.items.registered.forEach(e=>e.selected = !!(e._modified && !Object.values(e._changed).every(e=>!e)));
      }
      let selected = this.items.registered.filter(e=>e.selected);
      if (selected.length === 0) return alert('상품을 선택해 주시기 바랍니다.');
      this.modal.saveRegistered = true;
    },
    async saveRegistered() {
      let selected = this.items.registered.filter(e=>e.selected);
      let modified = selected.filter(e=>e._modified);
      let goods = modified.map(e=>{
        let obj = {goods_no: e.goods_no};
        Object.keys(e._changed).forEach(key=>{
          obj[key] = e[key];
        });
        return obj;
      });

      // return ;
      let j = await this.$api.postJson('/goods/uploadGoods', {type:'registered', goods});
      if (j && j.ok === 1) {
        if (j.warnings.length) {
          let body = j.warnings.map(e=>`<span class="badge badge-light">${e.goods_no}</span> - ${e.warning||''}`).join('<br/>');
          this.$modal.show({title:'업로드 이슈 확인', html:`<pre>${body}</pre><br/><br/>${j.cnt} 건 저장되었습니다.`});
        } else {
          utils.alert(`${j.cnt} 건 저장되었습니다.`);
        }
        let noMap = utils.arr2map(goods, 'goods_no');
        this.items.registered.filter(e=>noMap[e.goods_no]).forEach(row=>{
          let good = noMap[row.goods_no];
          Object.keys(row).filter(k=>good[k]).forEach(k=>{
            row[k] = good[k];
            row._org[k] = good[k];
          }); // row에 있는 key만 덮어쓴다
          row._changed = {}; row._modified = false;
        });
      } else if (j && j.ok === -1) {
        let body = `<h4>${j.msg}</h4>` + j.errors.map(e=>`<span class="badge badge-light">${e.goods_no}</span> - ${e.error||''}`).join('<br/>');
        this.$modal.show({title:'업로드 에러 확인', html:'<pre>' + body + '</pre>'});
      }
    },
    async undoRegistered() {
      let selected = this.items.registered.filter(e=>e.selected);
      if (selected.length === 0) return alert('상품을 선택해 주시기 바랍니다.');
      let modified = selected.filter(e=>e._modified);
      if (!confirm(`선택한 ${selected.length} 개의 상품중 ${modified.length} 개가 수정되었습니다. 수정을 취소하고 원래 값으로 되돌리시겠습니까?`)) return;
      modified.forEach(e=>{
        Object.keys(e._changed).forEach(key=>{
          e[key] = e._org[key];
        });
        e._modified = false;
      });
      this.changed.registered = this.items.registered.filter(e=>e._modified).length;
    },
    toggleUsedGrade(grade) {
      if (!grade) {
        this.formRegistered.usedGrade = this.formRegistered.usedGrade.length === this.$C.USED_GRADE.length ? [] : this.$C.USED_GRADE.map(e => e.value);
      } else {
        this.formRegistered.usedGrade = this.$C.USED_GRADE.filter(e => e.value[0] === grade).map(e => e.value);
      }
    },
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <b-input-group class="mb-3">
      <b-input-group-prepend>
        <b-button variant="primary" @click="listRegistered">
          <i class="fa fa-search"></i> 검색
        </b-button>
      </b-input-group-prepend>
      <b-form-input type="text" placeholder="goods_no, goods_id, sku_id를 넣어주세요" v-model="formRegistered.search" @keypress.enter.prevent.stop="listRegistered" v-focus></b-form-input>
    </b-input-group>
    <shop-preset class="mb-3" v-model="formRegistered.shop"></shop-preset>

    <b-collapse id="collapseRegistered">
      <b-row class="">
        <b-col cols="12" lg="6">
          <brand-preset class="mb-2" v-model="formRegistered.brand" :hideDisabled="true"></brand-preset>
        </b-col>
        <b-col cols="12" lg="6">
          <b-button class="mr-1 mb-1" size="sm" variant="warning" @click="formRegistered.category = []">초기화</b-button>
          <div class="clearfix">
          </div>
          <v-select v-model="formRegistered.category" multiple :options="category" :placeholder="category.length ? `전체 ${category.length} 개 카테고리` : '로딩중...'"></v-select>
        </b-col>
      </b-row>

      <b-row class="">
        <b-col md="3">
          <div><small class="mb-n2">정렬순서</small></div>
          <b-form-radio-group class="col-form-label" v-model="formRegistered.orderby" :options="[
            {text: '정렬없음', value: 'no_order'},
            {text: '발란코드', value: 'goods_no'},
            {text: '높은가격', value: 'price_high'},
            {text: '낮은가격', value: 'price_low'},
          ]"></b-form-radio-group>
        </b-col>
        <b-col md="2">
          <div><small class="mb-n2">재고상태</small></div>
          <b-form-radio-group class="col-form-label" v-model="formRegistered.stock_status" :options="[
                {text: '전체', value: 'ALL'},
                {text: '재고있음', value: 'normal'},
                {text: '품절', value: 'runout'}
              ]"></b-form-radio-group>
        </b-col>
        <b-col md="2">
          <div><small class="mb-n2">노출상태</small></div>
          <b-form-radio-group class="col-form-label" v-model="formRegistered.display_status" :options="[
                {text: '전체', value: 'ALL'},
                {text: '노출', value: 'view'},
                {text: '미노출', value: 'notview'}
              ]"></b-form-radio-group>
        </b-col>
      </b-row>
      <b-row class="">
        <b-col md="3">
          <div><small class="mb-n2">연동형태</small></div>
          <b-form-radio-group class="col-form-label" v-model="formRegistered.manual" :options="[
            {text: '전체', value: 'ALL'},
            {text: '자동(FEED)', value: 'auto'},
            {text: '파트너관리', value: 'manual'}
          ]"></b-form-radio-group>
        </b-col>
        <b-col md="6">
          <div><small class="mb-n2">상품유형</small></div>
          <b-form inline>
            <b-form-radio-group class="col-form-label" v-model="formRegistered.goodsType" :options="[
              {text: '전체', value: 'ALL'},
              {text: '새상품만', value: 'new'},
              {text: '빈티지만', value: 'used'}
            ]"></b-form-radio-group>
            <template v-if="formRegistered.goodsType === 'used'">
              <b-button class="mr-1" size="sm" variant="primary" @click="toggleUsedGrade()">전체</b-button>
              <b-button class="mr-1" size="sm" variant="primary" @click="toggleUsedGrade('S')">S</b-button>
              <b-button class="mr-1" size="sm" variant="success" @click="toggleUsedGrade('A')">A</b-button>
              <b-button class="mr-2" size="sm" variant="warning" @click="toggleUsedGrade('B')">B</b-button>
              <b-form-checkbox-group v-model="formRegistered.usedGrade">
                <b-form-checkbox v-for="s in $C.USED_GRADE" :key="s.value" :value="s.value" :title="s.desc">{{s.text}}</b-form-checkbox>
              </b-form-checkbox-group>
            </template>
          </b-form>
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col md="4">
          <small>생성시각</small><br/>
          <b-form inline>
            <b-form-input class="text-center" v-model="formRegistered.created_from" placeholder="2020-04-01 00:00:00"></b-form-input>
            ~
            <b-form-input class="text-center" v-model="formRegistered.created_to" placeholder="2020-04-01 00:00:00"></b-form-input>
          </b-form>
        </b-col>
        <!--<b-col md="4">
          <small>송출시각</small><br/>
          <b-form inline>
            <b-form-input class="text-center" v-model="formRegistered.registered_from" placeholder="2020-04-01 00:00:00"></b-form-input>
            ~
            <b-form-input class="text-center" v-model="formRegistered.registered_to" placeholder="2020-04-01 00:00:00"></b-form-input>
          </b-form>
        </b-col>-->
        <b-col md="4">
          <small>최근수정시각</small><br/>
          <b-form inline>
            <b-form-input class="text-center" v-model="formRegistered.updated_from" placeholder="2020-04-01 00:00:00"></b-form-input>
            ~
            <b-form-input class="text-center" v-model="formRegistered.updated_to" placeholder="2020-04-01 00:00:00"></b-form-input>
          </b-form>
        </b-col>
        <b-col md="4">
          <div><small class="mb-n2">한 번에 가져올 상품 수</small></div>
          <b-form-input type="text" placeholder="한 번에 가져올 상품 수" v-model.number="formRegistered.limit"></b-form-input>
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col md="4">
          <small>goods_no 범위선택</small><br/>
          <b-form inline>
            <b-form-input class="text-center" v-model.number="formRegistered.goods_no_from" placeholder="시작"></b-form-input>
            ~
            <b-form-input class="text-center" v-model.number="formRegistered.goods_no_to" placeholder="끝"></b-form-input>
          </b-form>
        </b-col>
        <b-col>
          <small>goods_no</small><br/>
          <b-form-textarea :rows="2" v-model="formRegistered.goods_no" placeholder="goods_no를 입력해주세요"></b-form-textarea>
        </b-col>
      </b-row>
    </b-collapse>
    <b-button class="mr-1" variant="primary" @click="listRegistered" :disabled="busy.registered"><b-spinner class="mr-2" small v-if="busy.registered"></b-spinner>검색</b-button>
    <b-button class="" variant="outline-success" v-b-toggle.collapseRegistered>상세검색조건</b-button>

    <hr/>

    <div class="clearfix mb-1">
      <b-button class="mr-1" size="sm" variant="dark" @click="modal.registeredField = true"><i class="fa fa-pencil"></i></b-button>
      <b-button class="mr-1" size="sm" variant="primary" @click="selectAllGoods()">전체 선택/해제</b-button>
      <b-button class="mr-1" size="sm" variant="success" @click="checkRegistered('all')">전체변경저장</b-button>
      <b-button class="mr-1" size="sm" variant="success" @click="checkRegistered">저장</b-button>
      <span>변경된 상품수 : {{changed.registered}}</span>
      <b-button class="ml-1 mr-1" size="sm" variant="warning" @click="undoRegistered()">되돌리기</b-button>
      <b-button class="mr-1" size="sm" variant="success" @click="down(items.registered, 'xlsx', 'Registered')">xlsx Down</b-button>
      <b-button class="mr-1" size="sm" variant="outline-success" @click="_=>{$refs.xlsxRegistered.value = null;$refs.xlsxRegistered.click()}">xlsx Up</b-button>
      <input type="file" ref="xlsxRegistered" data-type="registered" style="display: none" @change="handleXlsx">

      <!--<div class="pull-right">
        <b-button class="mr-1" size="sm" variant="info" @click="">이미지 갱신</b-button>
        <b-button class="mr-1" size="sm" variant="info" @click="">가격 갱신</b-button>
        <b-button class="mr-1" size="sm" variant="danger" @click="">Terminate</b-button>
      </div>-->
    </div>
    <hot-table data-key="registered" ref="hotTableRegistered" :settings="hotSettingRegistered"></hot-table>
    <small>* goods_status : 'registered' 인 상품들입니다.</small>

    <b-modal title="저장 및 전파" size="lg" v-model="modal.saveRegistered" @ok="saveRegistered">
      <b-alert show variant="info">총 {{items.registered.filter(e=>e.selected).length}} 건의 상품정보를 변경합니다</b-alert>
      <small class="text-muted">발란몰에만 바로 전파됩니다.</small>
    </b-modal>

    <b-modal title="Registered 필드 선택" size="lg" v-model="modal.registeredField" ok-only @ok="setField()">
      <b-alert show variant="info">선택한 필드만 보이게 합니다.</b-alert>
      <b-form-checkbox-group v-model="regCols">
        <b-form-checkbox class="d-block" v-for="e in regColBase" :value="e.data">{{e.name}} ({{e.data}})</b-form-checkbox>
      </b-form-checkbox-group>
    </b-modal>
  </div>
</template>
