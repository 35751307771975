import { render, staticRenderFns } from "./ProcessingTabRegistered.vue?vue&type=template&id=b77ca432&scoped=true&"
import script from "./ProcessingTabRegistered.vue?vue&type=script&lang=js&"
export * from "./ProcessingTabRegistered.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b77ca432",
  null
  
)

export default component.exports